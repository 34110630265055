import React, { lazy, Suspense } from "react"

import { useHydration } from "@app/hooks/useHydration"

type IconProps = {
  name: string
  fill?: string
  width?: string
  height?: string
}

export const Icon: React.FC<IconProps> = ({ name, fill, width, height }) => {
  const { isHydrated } = useHydration()

  const hasSize = !!(width && height)

  const StaticIcon = isHydrated && lazy(() => import(`../../static/icons/${name}.svg`))

  return StaticIcon ? (
    <Suspense fallback={<></>}>
      <StaticIcon fill={fill} {...(hasSize && { width: width, height: height })} />
    </Suspense>
  ) : (
    <></>
  )
}

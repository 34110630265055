import { label } from "./Form"

export const field = {
  px: 4,
  py: 3,
  h: "auto",
  border: "1px solid",
  borderColor: "border.default",
  mb: 4,
  outline: "0px",
  borderRadius: "xl",
  fontSize: "md",
  color: "typography.headlines900",
  _placeholder: {
    ...label,
  },
  _focusWithin: {
    borderColor: "border.focus",
  },
  _invalid: {
    borderColor: "functional.errorContent",
  },
  _disabled: {
    backgroundColor: "background.inputDisabled",
    color: "typography.body600",
    _placeholder: {
      color: "typography.body600",
    },
  },
}

export const Input = {
  variants: {
    default: {
      field: {
        ...field,
      },
    },
    quantity: {
      field: {
        flex: 1,
        px: 4,
        py: 3,
        h: "auto",
        fontSize: "sm",
        color: "typography.headlines900",
        textAlign: "center",
        outline: "0px",
        _disabled: {
          transition: "none",
          cursor: "not-allowed",
          backgroundColor: "transparent",
          color: "typography.body600",
          _placeholder: {
            color: "typography.body600",
          },
        },
      },
    },
  },
  defaultProps: {
    variant: "default",
  },
}

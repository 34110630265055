import React from "react"
import { Global as EmotionGlobal } from "@emotion/react"
import "react-date-picker/dist/DatePicker.css"
import "react-calendar/dist/Calendar.css"

export const Global: React.FC = () => (
  <EmotionGlobal
    styles={`
      @font-face {
        font-family: 'Futura Std';
        src: url('/fonts/FuturaStdHeavy.woff2') format('woff2'),
            url('/fonts/FuturaStdHeavy.woff') format('woff');
        font-weight: 650;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Futura Std';
        src: url('/fonts/FuturaStdBook.woff2') format('woff2'),
            url('/fonts/FuturaStdBook.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      html {
        scroll-behavior: auto !important;
      }

      .swiper-container {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        height: auto;
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .react-date-picker {
        display: flex !important;
        padding: 0 !important;
        border: 1px solid !important;
        border-color: #E4E4E4 !important;
        outline: 0px !important;
        border-radius: 12px !important;
      }
      
      .react-date-picker:focus-within {
        border-color: #555555 !important;
      }
      
      .react-date-picker__wrapper {
        position: relative !important;
        border: none !important;
      }
      
      .react-date-picker__inputGroup {
        padding: 12px 16px !important;
      }
      
      .react-date-picker__inputGroup__input {
        outline: none !important;
      }
      
      .react-date-picker__inputGroup__input:invalid {
        background: transparent !important;
      }
      
      .react-date-picker__calendar {
        z-index: 1800 !important;
      }
      
      .react-date-picker__calendar--open, .react-date-picker__calendar--closed {
        position: absolute !important;
      }
      
      .react-date-picker__calendar-button {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
      }
      
      .react-date-picker__calendar-button svg {
        display: none !important;
      }

      #gorgias-chat-container {
        position: relative;
        z-index: 9999;
      }
    `}
  />
)

export default Global

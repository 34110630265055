import React, { useState, useEffect, useCallback, useMemo } from "react"
import { useQuery } from "@apollo/client"
import { useCore, useStorage } from "@app/hooks/useCore"
// import { useCheckout } from "@app/hooks/useCheckout"
import { useConfigContext } from "@app/providers/config"

type ContextProps = {
  customer: any
  getCustomer: () => void
  setCustomer: (customer: any) => void
  clearCustomer: () => void
  saveCustomer: (customerAccessToken: any) => void
}

export const CustomerContext = React.createContext<ContextProps | undefined>(undefined)

export const CustomerProvider: React.FC = ({ children }) => {
  const {
    graphql: {
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  // const { updateCustomer } = useCheckout()
  const [customer, setCustomer] = useState(false)

  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const token = useMemo(() => getStorage(keys.customer), [getStorage, keys])

  useEffect(() => {
    getCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCustomer = useCallback(async () => {
    if (token?.accessToken) {
      try {
        const {
          data: { customer, customerUserErrors },
        } = await getCustomerQuery({
          customerAccessToken: token?.accessToken,
        })

        if (!customerUserErrors?.length) setCustomer(customer)
        if (customerUserErrors?.length) removeStorage(keys.customer)
      } catch (e) {
        console.error((e as Error).message)
      }
    }
  }, [getCustomerQuery, setCustomer, removeStorage, keys, token])

  const saveCustomer = useCallback(
    customerAccessToken => {
      try {
        const { accessToken, expiresAt } = customerAccessToken
        setStorage(keys.customer, { accessToken, expiresAt })
        getCustomer()
        // updateCustomer(accessToken)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [getCustomer, setStorage, keys]
  )

  const clearCustomer = useCallback(() => {
    try {
      removeStorage(keys.customer)
      setCustomer(false)
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [setCustomer, removeStorage, keys])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      customer,
      getCustomer,
      setCustomer,
      saveCustomer,
      clearCustomer,
    }),
    [customer, getCustomer, saveCustomer, clearCustomer]
  )

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>
}

export const useCustomerContext = (): ContextProps => ({ ...React.useContext(CustomerContext) }) as ContextProps

const baseSizeStyles = {
  py: 0.5,
  px: 3,
  textTransform: "none",
  letterSpacing: "wide",
}

const baseVariantStyles = {
  color: "background.white",
  borderRadius: "2xl",
}

export const Badge = {
  variants: {
    primary: {
      ...baseVariantStyles,
      background: "brand.secondaryFocus",
    },
    secondary: {
      ...baseVariantStyles,
      background: "functional.successContent",
    },
    tertiary: {
      ...baseVariantStyles,
      background: "functional.errorContent",
    },
  },
  sizes: {
    small: {
      ...baseSizeStyles,
      fontSize: "xs",
      lineHeight: "1.83",
    },
    large: {
      ...baseSizeStyles,
      fontSize: "sm",
      lineHeight: "base",
    },
  },
  defaultProps: {
    variant: "primary",
    size: "small",
  },
}

export const Heading = {
  baseStyle: {
    fontWeight: "bold",
    fontFamily: "heading",
    letterSpacing: "wide",
  },
  sizes: {
    h1: {
      fontSize: { base: "3xl", lg: "5xl" },
      lineHeight: { base: "1.2", lg: "1.42" },
    },
    h2: {
      fontSize: { base: "2xxl", lg: "4xl" },
      lineHeight: { base: "1.23", lg: "1.11" },
    },
    h3: {
      fontSize: { base: "2xl", lg: "3xl" },
      lineHeight: { base: "short", lg: "1.2" },
    },
    h4: {
      fontSize: { base: "xl", lg: "2xl" },
      lineHeight: { base: "1.4", lg: "short" },
    },
    h5: {
      fontSize: { base: "lg", lg: "xl" },
      lineHeight: { base: "1.77", lg: "1.4" },
    },
  },
}

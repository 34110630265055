export const Switch = {
  variants: {
    default: {
      container: {
        "--switch-track-width": "36px",
        "--switch-track-height": "20px",
      },
      track: {
        boxSizing: "border-box",
        p: "3px",
        backgroundColor: "border.default",
        _focus: {
          boxShadow: "none",
        },
        _checked: {
          backgroundColor: "brand.primary",
          _disabled: {
            backgroundColor: "border.default",
          },
        },
        _disabled: {
          opacity: "1",
          span: {
            backgroundColor: "typography.body600",
          },
        },
      },
      thumb: {
        width: 3.5,
        height: 3.5,
        borderRadius: "md",
      },
    },
  },
  defaultProps: {
    variant: "default",
  },
}

import { useMemo, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useRoutes } from "@app/hooks/useRoutes"
import { useCore } from "@app/hooks/useCore"
import { usePromotions } from "@app/hooks/usePromotions"

export const useNavigation = () => {
  const { urlResolver } = useRoutes()
  const { menu } = usePromotions()
  const {
    helpers: { edgeNormaliser },
  } = useCore()

  const { main, all } = useStaticQuery<GatsbyTypes.StaticMenuQuery>(graphql`
    query StaticMenu {
      main: sanitySettingMenus {
        header {
          items: _rawItems(resolveReferences: { maxDepth: 6 })
          handle {
            current
          }
        }
        headerSecondary {
          items: _rawItems(resolveReferences: { maxDepth: 6 })
          handle {
            current
          }
        }
        footer {
          items: _rawItems(resolveReferences: { maxDepth: 4 })
          handle {
            current
          }
        }
      }
      all: allSanityNavigation(filter: { handle: { current: { nin: ["header", "footer"] } } }) {
        edges {
          node {
            items: _rawItems(resolveReferences: { maxDepth: 6 })
            handle {
              current
            }
          }
        }
      }
    }
  `)

  const buildItems: any = useCallback(
    (items: any[]) =>
      items?.map((link: any) => {
        const type = link?._type?.replace("navigation", "").toLowerCase()
        const internal = ["link", "sub"].includes(type)
        const item = !internal ? link[type] || link.link : false
        const items = buildItems(link?.items) || false
        const resolvedUrl = urlResolver(item)
        const title = link?.title || resolvedUrl?.title
        const image = type === "sub" ? link?.link?.image : link?.image || resolvedUrl?.image
        const url = !internal ? resolvedUrl?.url : link.link || false

        return {
          type,
          title,
          url: typeof url !== "string" ? urlResolver(url)?.url : url,
          image: image?.asset?.url || image?.url || image,
          ...(item && { item }),
          ...(items && { items }),
        }
      }) || [],
    [urlResolver]
  )

  const header = useMemo(
    () =>
      buildItems(
        menu === "default"
          ? main?.header?.items
          : edgeNormaliser(all)?.find(({ handle }: { handle: any }) => handle?.current === menu)?.items
      ),
    [menu, main, all, buildItems, edgeNormaliser]
  )

  const headerSecondary = useMemo(() => buildItems(main?.headerSecondary?.items), [main, buildItems])

  const footer = useMemo(() => buildItems(main?.footer?.items), [main, buildItems])

  // const account = useMemo(() => buildItems(main?.account?.items), [main, buildItems])

  return { header, headerSecondary, footer }
}

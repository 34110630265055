export const Modal = {
  sizes: {
    upsell: {
      dialog: {
        maxW: "1024px",
        maxH: { md: "90vh" },
        mt: "0",
        mb: "0",
        mx: { base: "0", md: "8" },
        w: { base: "full", md: "calc(100% - 64px)", lg: "full" },
        borderRadius: { base: "none", md: "xl" },
      },
      dialogContainer: {
        alignItems: { base: "initial", md: "center" },
      },
      body: {
        overflow: "auto",
        borderRadius: { base: "none", md: "xl" },
      },
      footer: {
        bg: "white",
      },
    },
    option1: {
      dialog: {
        maxW: "972px",
        maxH: { md: "706px" },
        height: "var(--window-height)",
        mt: "0",
        mb: "0",
        mx: { base: "0", md: "8" },
        w: { base: "full", md: "calc(100% - 64px)", lg: "full" },
        borderRadius: "none",
      },
      dialogContainer: {
        alignItems: { base: "initial", md: "center" },
        "@supports(height: -webkit-fill-available)": {},
        height: "full",
      },
      body: {
        overflow: { base: "auto", md: "hidden" },
        borderRadius: { base: "none", md: "xl" },
        h: "full",
      },
    },
    editor: {
      dialog: {
        maxW: "800px",
        maxH: "98%",
        height: "auto",
        mt: "0",
        mb: "0",
        mx: { base: "0", md: "8" },
        w: { base: "full", md: "calc(100% - 64px)", lg: "full" },
        borderRadius: "none",
      },
      dialogContainer: {
        alignItems: { base: "initial", md: "center" },
        "@supports(height: -webkit-fill-available)": {},
        height: "full",
      },
      body: {
        overflow: "auto",
        borderRadius: { base: "none", md: "xl" },
        h: "full",
      },
    },
  },
}

import { field } from "./Input"

export const Textarea = {
  variants: {
    default: {
      ...field,
      minHeight: "120px",
      resize: "none",
    },
  },
  defaultProps: {
    variant: "default",
  },
}

import React from "react"
import { Text } from "@chakra-ui/react"

type Props = {
  title: string
}

const Copyright: React.FC<Props> = ({ title }) => (
  <Text size="paragraphLarge" mt={{ base: "5", md: "0" }}>
    &copy; {new Date().getFullYear()} {title}
  </Text>
)

export default React.memo(Copyright)

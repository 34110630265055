import { container } from "@app/theme/foundations/breakpoints"

export const sizes = {
  container,
  6.5: "26px",
  4.5: "18px",
  5.5: "22px",
  10.5: "42px",
  11.5: "46px",
  14: "56px",
  15: "60px",
  18: "72px",
  18.5: "74px",
  23.5: "94px",
  25: "100px",
  26: "104px",
  30: "120px",
  37.5: "150px",
  40: "160px",
  42: "168px",
  43.5: "174px",
  44: "176px",
  45: "180px",
  48.5: "194px",
  50: "200px",
  52.5: "210px",
  57.5: "230px",
  70: "280px",
  72.5: "290px",
  75: "300px",
  76: "304px",
  80: "320px",
  100: "400px",
  125: "500px",
  133: "532px",
  150: "600px",
  160: "640px",
  180: "720px",
  200: "800px",
  215: "860px",
  "2xl": "40rem",
  "9xl": "100rem",
  "10xl": "110rem",
  "11xl": "120rem",
}

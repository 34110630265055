export const styles = {
  global: {
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      bg: "white",
      color: "typography.body700",
    },
    a: {
      color: "typography.body700",
      _hover: {
        textDecoration: "underline",
      },
    },
  },
}

import React from "react"
import { AspectRatio, Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react"

import Link from "@app/components/Link"
import ProductPrice from "@app/components/Product/ProductPrice"
import { useCart } from "@app/hooks/useCart"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import { Icon } from "@app/components/Icon"
import { NormalisedCheckoutLineItem } from "@root/types/custom-types/Checkout/CheckoutLineItem"

type Props = {
  item: NormalisedCheckoutLineItem
  remove: string
}

const CartItem: React.FC<Props> = ({ item, remove }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { loading: cartLoading, removeFromCart, updateQuantity } = useCart()
  const loading = cartLoading
  const itemUrl = urlResolver(item?.variant?.product, routes.PRODUCT)?.url
  const isDefaultVariant = !!item?.variant?.title.toLowerCase().includes("default")

  return (
    <Box mb={4}>
      <Flex alignItems="center" justifyContent="space-between" gap="4">
        <Link to={itemUrl} d="block" w="30">
          <AspectRatio ratio={1}>
            <Image src={item.variant?.image?.transformedSrc} alt={item.title} objectFit="cover" />
          </AspectRatio>
        </Link>
        <Box w="calc(100% - 120px)">
          <Link to={itemUrl} d="block" mb="1">
            <Text size="mediumParagraph">{item.title}</Text>
          </Link>
          {item?.variant?.title && !isDefaultVariant && (
            <Text size="smallParagraph" mb="2">
              {item?.variant?.title}
            </Text>
          )}
          <ProductPrice variant={item?.variant} />

          <Flex justifyContent="space-between">
            <Box
              d="inline-flex"
              alignItems="center"
              justifyContent="space-between"
              w="30"
              border="1px solid"
              borderColor="border.default"
              borderRadius="xl"
              overflow="hidden"
            >
              <Button
                variant="unstyled"
                disabled={loading}
                onClick={() => updateQuantity(item?.id, item?.variant?.id, item?.quantity - 1)}
                d="inline-flex"
                justifyContent="center"
                alignItems="center"
                w="11.5"
              >
                <Icon name="ui/minus" width="20" height="20" />
              </Button>
              <Input
                variant="unstyled"
                type={"number"}
                onChange={({ target: { value } }) => updateQuantity(item?.id, item?.variant?.id, parseInt(value))}
                value={item?.quantity}
                textAlign="center"
                p="0"
                fontSize="sm"
                lineHeight="base"
                letterSpacing="wide"
                color="typography.headlines900"
                w="7"
              />
              <Button
                variant="unstyled"
                disabled={loading}
                onClick={() => updateQuantity(item?.id, item?.variant?.id, item?.quantity + 1)}
                d="inline-flex"
                justifyContent="center"
                alignItems="center"
                w="11.5"
              >
                <Icon name="ui/plus" width="20" height="20" />
              </Button>
            </Box>

            <Button
              variant="unstyled"
              disabled={loading}
              onClick={() => removeFromCart(item?.id, item?.variant?.id)}
              fontSize="xs"
              lineHeight="base"
              letterSpacing="wide"
              color="typography.headlines900"
              fontWeight="normal"
              textTransform="capitalize"
            >
              {remove}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default React.memo(CartItem)

import React from "react"
import { Box, FormControl, FormErrorMessage, Input, Text, InputProps, IconButton, InputRightElement, InputGroup } from "@chakra-ui/react"

import { useNewsletter } from "@app/hooks/useNewsletter"
import { Icon } from "@app/components/Icon"

const SubscribeForm: React.FC<InputProps> = props => {
  const { newsletter, data, errors, handleSubmit, handleChange, loading, success } = useNewsletter()

  return !success ? (
    <Box as="form" onSubmit={handleSubmit} maxW="2xl" mx="auto">
      <InputGroup>
        <Input
          name="email"
          type="email"
          placeholder={newsletter?.emailPlaceholder}
          value={data?.email}
          isRequired
          onChange={handleChange}
          mb="0"
          pr={{ base: "16" }}
          borderColor="transparent"
          {...props}
        />
        <InputRightElement h="100%">
          <IconButton
            type="submit"
            isDisabled={loading}
            isLoading={loading}
            variant="unstyled"
            icon={<Icon name="arrows/arrow-right" width="20px" height="20px" />}
            aria-label="Subscribe"
            d="inline-flex"
            justifyContent="center"
            alignItems="center"
            w={{ base: "12" }}
            h="100%"
          />
        </InputRightElement>
      </InputGroup>

      {errors?.length > 0 && (
        <FormControl id="error" isInvalid>
          {errors?.map(error => <FormErrorMessage key={error?.toString()}>{error?.toString()}</FormErrorMessage>)}
        </FormControl>
      )}
    </Box>
  ) : (
    <Text size="headlines" color="background.white" textAlign="center">
      {newsletter?.successMessage}
    </Text>
  )
}

export default React.memo(SubscribeForm)

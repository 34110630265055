export const Link = {
  baseStyle: {
    _hover: {
      background: "none",
      textDecoration: "none",
    },
    _focus: {
      boxShadow: "none",
    },
  },
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, HStack, StackProps } from "@chakra-ui/react"
import { Icon } from "@app/components/Icon"
import { useCore } from "@app/hooks/useCore"

const PaymentTypes: React.FC<StackProps> = props => {
  const { types } = useStaticQuery<GatsbyTypes.StaticPaymentsQuery>(graphql`
    query StaticPayments {
      types: sanitySettingPayments {
        title
        enabled
      }
    }
  `)
  const {
    helpers: { ErrorBoundary },
  } = useCore()

  return types?.enabled && !!types.enabled.length ? (
    <ErrorBoundary>
      <HStack spacing="2" mb={{ base: "5", mb: "0" }} {...props}>
        {types.enabled.map((item, index) => {
          if (!item) return
          const iconName = `payment/${item.toLowerCase()}`
          return (
            <Box key={index} as="span" w="9" h="6">
              <Icon name={iconName} width="100%" height="100%" />
            </Box>
          )
        })}
      </HStack>
    </ErrorBoundary>
  ) : null
}
export default React.memo(PaymentTypes)

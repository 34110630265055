import { useCallback, useEffect, useState } from "react"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"

export const useKlaviyo = () => {
  const {
    store: { url },
    settings: { routes },
  } = useConfigContext()
  const {
    helpers: { isDev },
  } = useCore()

  const [preInitEvents, setPreInitEvents] = useState<any[]>([])

  const initLearnq = useCallback(() => {
    if (typeof window !== `undefined` && window._learnq && preInitEvents.length > 0) {
      preInitEvents.forEach(event => {
        window._learnq.push(event)
      })
      setPreInitEvents([])
    }
  }, [preInitEvents])

  useEffect(() => {
    initLearnq()
  }, [initLearnq, preInitEvents])

  // Run after 4 seconds to ensure that the _learnq object is available
  // then fire off all stored events
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setTimeout(() => {
        initLearnq()
      }, 4000)
    }
  }, [initLearnq, preInitEvents])

  const cleanData = (data: any) => {
    try {
      const clean = JSON.parse(
        JSON.stringify(data, (k, v) =>
          ["id", "internal", "parent", "metadata"].includes(k) || k.startsWith("_")
            ? undefined
            : ["image", "thumbnail"].includes(k)
              ? { alt: v?.alt, url: v?.asset?.url }
              : ["handle"].includes(k)
                ? v?.current
                : ["date", "dateCreated", "dateUpdated", "publishedAt"].includes(k)
                  ? `${new Date(v).toISOString()}`
                  : ["content", "subcontent", "additionalContent", "description"].includes(k) && Array.isArray(v)
                    ? v?.map(({ children }) => children?.[0]?.text)?.join("<br/>")
                    : Array.isArray(v) && !v?.length
                      ? undefined
                      : v?.constructor === Object && Object.entries(v)?.length === 0
                        ? undefined
                        : typeof v === "boolean"
                          ? v
                          : v || undefined
        )
      )
      return clean
    } catch (err) {
      console.error("Error parsing klaviyo event data")
    }
    return
  }

  const identify = useCallback(
    (customer: { email: string; firstName?: string; lastName?: string }) => {
      if (isDev) console.log(`[KLAVIYO] identify fired.`)

      if (typeof window !== `undefined` && !window._learnq) {
        setPreInitEvents(prev => [...prev, ["identify", { $email: `${customer?.email}` }]])
      } else {
        window._learnq.push([
          "identify",
          {
            $email: `${customer?.email}`,
            $first_name: `${customer?.firstName || ""}`,
            $last_name: `${customer?.lastName || ""}`,
            // Custom Properties...
          },
        ])
      }
    },
    [isDev]
  )

  const track = useCallback(
    (type: string, item: any) => {
      if (isDev) console.log(`[KLAVIYO] track(${type}) fired.`)

      const trackEvent = ["track", type, { ...cleanData(item) }]

      if (typeof window !== `undefined` && !window._learnq) {
        setPreInitEvents(prev => [...prev, trackEvent])
      } else {
        window._learnq.push(trackEvent)
      }
    },
    [isDev]
  )

  const trackProduct = useCallback(
    (product: any) => {
      const { title, id, collections, images, vendor, variants, handle } = product
      if (title) {
        const item = {
          Name: title,
          ProductID: id,
          Categories: collections?.map((collection: any) => collection.title),
          ImageURL: images?.[0]?.originalSrc,
          URL: `${url}${routes.PRODUCT}/${handle}`,
          Brand: vendor,
          Price: variants?.[0]?.priceV2?.amount,
        }
        track("Viewed Product", {
          Title: item.Name,
          ImageURL: item.ImageURL,
          URL: item.URL,
        })
        track("trackViewedItem", {
          Title: item.Name,
          ItemId: item.ProductID,
          Categories: item.Categories,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price,
          },
        })
      }
    },
    [url, routes.PRODUCT, track]
  )

  return { identify, track, trackProduct }
}

import React, { lazy } from "react"

import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"

export type MenuItem = {
  url: string
  type: string
  title: string
  image?: string
  items: MenuItem[]
  handleClick?: () => void
}

const NavigationHeader: React.FC = () => {
  const {
    helpers: { ErrorBoundary },
  } = useCore()
  const { isLarge } = useMedia()

  const NavigationHeaderDesktop = lazy(() => import("./NavigationHeaderDesktop"))
  const NavigationHeaderMobile = lazy(() => import("./NavigationHeaderMobile"))

  return <ErrorBoundary>{isLarge ? <NavigationHeaderDesktop /> : <NavigationHeaderMobile />}</ErrorBoundary>
}

export default React.memo(NavigationHeader)

import React from "react"

import { AppProvider } from "@app/providers/app"
import { ThemeProvider } from "@app/providers/theme"
import { ConfigProvider } from "@app/providers/config"
import { ShopifyProvider } from "@app/providers/shopify"
import { LocationProvider } from "@app/providers/location"
import { TrackingProvider } from "@app/providers/tracking"
import { CustomerProvider } from "@app/providers/customer"
import { CheckoutProvider } from "@app/providers/checkout"
import { ProductsProvider } from "@app/providers/products"

const Providers: React.FC = ({ children }) => (
  <LocationProvider>
    <ConfigProvider>
      <ShopifyProvider>
        <AppProvider>
          <CustomerProvider>
            <CheckoutProvider>
              <ProductsProvider>
                <TrackingProvider>
                  <ThemeProvider>{children}</ThemeProvider>
                </TrackingProvider>
              </ProductsProvider>
            </CheckoutProvider>
          </CustomerProvider>
        </AppProvider>
      </ShopifyProvider>
    </ConfigProvider>
  </LocationProvider>
)

export default Providers

export const Progress = {
  sizes: {
    md: {
      track: {
        h: "1.5",
      },
    },
  },
  baseStyle: {
    filledTrack: {
      bg: "brand.secondaryFocus",
      borderRadius: "sm",
    },
    track: {
      bg: "background.beige",
      borderRadius: "sm",
    },
  },
}

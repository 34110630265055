import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Container, Flex, Show, Text } from "@chakra-ui/react"
import Copyright from "@app/components/Copyright"
import SocialMedia from "@app/components/SocialMedia"
import NavigationFooter from "@app/components/Navigation/Footer/NavigationFooter"
import PaymentTypes from "@app/components/PaymentTypes"

const Footer: React.FC = () => {
  const { organisation, footer } = useStaticQuery<GatsbyTypes.StaticLayoutQuery>(graphql`
    query StaticLayout {
      organisation: sanitySettingOrganisation {
        title
      }
      footer: sanityTemplateGlobal {
        footerTitle
        footerDescription
        footerAdditionalDescription
        footerSocialTitle
      }
    }
  `)

  return (
    <Box as="footer">
      <Container
        d={{ lg: "flex" }}
        flexDir={{ lg: "row-reverse" }}
        justifyContent={{ lg: "space-between" }}
        maxW="container.4xl"
        px={{ base: "4", md: "10.5", lg: "20" }}
        py={{ md: "7.5", lg: "14" }}
      >
        <Box w={{ lg: "min(66.8%, 682px)" }}>
          <NavigationFooter />

          {footer?.footerAdditionalDescription ? (
            <Text size="paragraphLarge" mt={{ base: "0", md: "6" }} mb={{ base: "4", md: "0" }}>
              {footer.footerAdditionalDescription}
            </Text>
          ) : null}
        </Box>

        <Box maxW={{ lg: "76" }}>
          <Show above="lg">
            {footer?.footerTitle && (
              <Text as="h2" size="headlines" color="typography.headlines900" mb="1.5">
                {footer.footerTitle}
              </Text>
            )}

            {footer?.footerDescription && (
              <Text size="paragraphLarge" mb="6">
                {footer.footerDescription}
              </Text>
            )}

            {footer?.footerSocialTitle && (
              <Text size="headlines" color="typography.headlines900" mb="6">
                {footer.footerSocialTitle}
              </Text>
            )}
          </Show>

          <SocialMedia />
        </Box>
      </Container>

      <Box borderTop="1px solid" borderColor="border.default">
        <Container maxW="container.4xl" py={{ base: "6" }} px={{ base: "0", md: "10.5", lg: "20" }}>
          <Flex
            flexDirection={{ base: "column", md: "row-reverse" }}
            justifyContent={{ md: "space-between" }}
            alignItems={{ base: "center", md: "flex-start" }}
          >
            <PaymentTypes />
            <Copyright title={organisation?.title || ""} />
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default React.memo(Footer)

import { useCallback } from "react"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useCheckoutContext } from "@app/providers/checkout"
import { useTrackingContext } from "@app/providers/tracking"
import { useCustomerContext } from "@app/providers/customer"
import { useKlaviyo } from "./useKlaviyo"
import { BundleTrackingData } from "./useBundle"

export const useAnalytics = () => {
  const { tracked, gtm, latestVersion } = useTrackingContext()
  const {
    helpers: { isDev, decodeShopifyId, formatPrice, capitalise },
  } = useCore()
  // const { activeVariant } = useAppContext() // TODO: Look to switch to this instead of passing variants
  const { checkout } = useCheckoutContext()
  const { customer } = useCustomerContext()
  const { productNormaliser } = useShopify()
  const { track, trackProduct } = useKlaviyo()

  const decorateUrl = (url: string): string => {
    if (typeof window !== "undefined") {
      // @ts-ignore next-line
      const ga = window[window["GoogleAnalyticsObject"]]
      // @ts-ignore next-line
      if (ga && typeof ga.getAll === "function") {
        // @ts-ignore next-line
        const tracker = ga.getAll()[0]
        // @ts-ignore next-line
        url = new window.gaplugins.Linker(tracker).decorate(url)
      }
      return url
    } else {
      return url
    }
  }

  const getVariantOptionValue = (options: any, selectedName: string) =>
    options?.find(({ name }: { name: string }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value

  const pushDataLayer = useCallback(
    (dataLayer: any) => {
      if (gtm) {
        if (isDev) console.log({ ...dataLayer })
        if (!isDev) gtm.dataLayer({ dataLayer })
      }
    },
    [gtm, isDev]
  )

  const trackPageView = useCallback(() => {
    if (tracked) {
      setTimeout(() => {
        const dataLayer = latestVersion
          ? {
              event: "page_view",
              page_title: document?.title,
              page_path: `${document?.location?.pathname}${document?.location?.search || ""}`,
              path_location: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
              ...(customer?.id && { customer_id: customer?.id }),
            }
          : {
              event: "Pageview",
              pagePath: `${document?.location?.pathname}${document?.location?.search ? document.location.search : ""}`,
              pageTitle: document?.title,
              originalLocation: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
            }
        pushDataLayer(dataLayer)
      }, 400)
    }
  }, [tracked, customer, latestVersion, pushDataLayer])

  const trackProductImpression = useCallback(
    async (product, index, list = null) => {
      const { collections, id, title, vendor, priceRange, productType } = productNormaliser(product)
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "view_item_list",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(product?.variants?.[0]?.selectedOptions, "Title"),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                    item_list_id: "", // TODO: Define lists
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productImpression",
              ecommerce: {
                currencyCode: checkout?.currencyCode || "AUD",
                impressions: [
                  {
                    id: id,
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || productType,
                    price: priceRange?.minVariantPrice?.amount,
                    list: list || "Collection", // Collection Results, Instant Search, Search Results, Featured Products, Related Products, Wishlist
                    position: index,
                  },
                ],
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [checkout, productNormaliser, decodeShopifyId, formatPrice, latestVersion, pushDataLayer]
  )

  const trackProductClick = useCallback(
    async (product, variant, index, list = null) => {
      const { collections, id, priceRange, title, vendor, productType } = product
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "select_item",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      "Title"
                    ),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                    item_list_id: "", // TODO: Define lists
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productClick",
              ecommerce: {
                currencyCode: checkout?.currencyCode || "AUD",
                click: {
                  actionField: { list: list || "Collection" }, // Collection Results, Instant Search, Search Results, Featured Products, Related Products, Wishlist
                  products: [
                    {
                      id: id,
                      name: title,
                      brand: vendor,
                      category: collections?.[0]?.title || productType,
                      price: priceRange?.minVariantPrice?.amount,
                      position: index,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        "Title"
                      ),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [checkout, decodeShopifyId, formatPrice, latestVersion, pushDataLayer]
  )

  const trackProductView = useCallback(
    async (product, variant, parentCollection) => {
      trackProduct(product)
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "view_item",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      "Title"
                    ),
                    item_category: parentCollection || productType || collections?.[0]?.title,
                    quantity: 1,
                    price: formatPrice(variant?.priceV2 || (product?.variants && product?.variants?.[0]?.priceV2?.amount)),
                  },
                ],
              },
            }
          : {
              event: "productDetail",
              ecommerce: {
                currencyCode: checkout?.currencyCode || "AUD",
                detail: {
                  actionField: { list: "Product Page" }, // Product Page
                  products: [
                    {
                      id: decodeShopifyId(id, "Product"),
                      name: title,
                      brand: vendor,
                      category: parentCollection || collections?.[0]?.title || productType,
                      price: product?.variants && product?.variants[0]?.priceV2?.amount,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        "Title"
                      ),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [checkout, productNormaliser, decodeShopifyId, formatPrice, latestVersion, pushDataLayer, trackProduct]
  )

  const trackCartUpdate = useCallback(
    async (type: string, lineitems: any[], rawItems: any[], bundle?: BundleTrackingData) => {
      if (type === "add") {
        track("Added to Cart", {
          bundleName: bundle?.title,
          bundleImage: bundle?.imageUrl,
          URL: bundle?.URL,
        })
      }
      const items = rawItems?.map?.((item: any) => {
        const selectedLineItem = lineitems?.find(({ variant }: { variant: any }) => variant?.id === item?.variantId)
        return {
          item_id: decodeShopifyId(item?.variantId, "ProductVariant"),
          item_name: selectedLineItem?.title,
          item_brand: selectedLineItem?.variant?.product?.vendor,
          item_variant: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, "Title"),
          item_category: selectedLineItem?.variant?.product?.productType,
          price: formatPrice(selectedLineItem?.variant?.priceV2?.amount),
          quantity: item?.quantity,
        }
      })

      if (items?.length) {
        const dataLayer = {
          event: ["add", "change"].includes(type) ? "add_to_cart" : "remove_from_cart",
          ecommerce: {
            currency: checkout?.currencyCode || "AUD",
            items,
          },
        }

        pushDataLayer(dataLayer)
      }
    },
    [checkout?.currencyCode, decodeShopifyId, formatPrice, pushDataLayer, track]
  )

  const trackCartView = useCallback(async () => {
    if (checkout?.lineItems?.length) {
      if (latestVersion) {
        const dataLayer = {
          event: "view_cart",
          ecommerce: {
            currency: checkout?.currencyCode || "AUD",
            items: checkout?.lineItems?.map((lineitem: any) => ({
              item_id: decodeShopifyId(lineitem?.variant?.id, "ProductVariant"),
              item_name: lineitem?.title,
              item_brand: lineitem?.variant?.product?.vendor,
              item_variant: getVariantOptionValue(lineitem?.variant?.selectedOptions, "Title"),
              item_category: lineitem?.variant?.product?.productType,
              price: formatPrice(lineitem?.variant?.priceV2?.amount),
              quantity: lineitem?.quantity,
            })),
          },
        }
        pushDataLayer(dataLayer)
      }
    }
  }, [checkout, decodeShopifyId, formatPrice, latestVersion, pushDataLayer])

  const trackWishlistUpdate = useCallback(
    async (type, product) => {
      if (product?.title) {
        if (latestVersion) {
          const dataLayer = {
            event: ["add"].includes(type) ? "add_to_wishlist" : "remove_from_wishlist",
            ecommerce: {
              currency: checkout?.currencyCode || "AUD",
              items: [
                {
                  item_id: decodeShopifyId(product?.selectedVariant?.id, "ProductVariant"),
                  item_name: product?.title,
                  item_brand: product?.vendor,
                  item_variant: getVariantOptionValue(product?.selectedVariant?.selectedOptions, "Title"),
                  item_category: product?.productType,
                  price: formatPrice(
                    product?.selectedVariant?.priceV2?.amount ?? product?.variants?.[0]?.priceV2?.amount ?? product?.variants?.[0]?.price
                  ),
                },
              ],
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [checkout, decodeShopifyId, formatPrice, latestVersion, pushDataLayer]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "view_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionView",
              ecommerce: {
                promoView: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "select_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionClick",
              ecommerce: {
                promoClick: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackLogin = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "login",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackSignup = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "sign_up",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackShare = useCallback(
    async (method, type, id) => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "share",
            ecommerce: {
              method: capitalise(method),
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackClick = useCallback(
    async (type, id) => {
      if (type) {
        if (latestVersion) {
          const dataLayer = {
            event: "select_content",
            ecommerce: {
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackSearch = useCallback(
    async term => {
      if (term) {
        if (latestVersion) {
          const dataLayer = {
            event: "search",
            ecommerce: {
              search_term: term,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  return {
    tracked,
    trackPageView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartView,
    trackCartUpdate,
    trackWishlistUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackLogin,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    decorateUrl,
  }
}

import React from "react"
import { AspectRatio, Box, Button, Flex, Image, Text } from "@chakra-ui/react"

import { useConfigContext } from "@app/providers/config"
import { useCart } from "@app/hooks/useCart"
import { useShopify } from "@app/hooks/useShopify"
import CartBundleSubItem from "@app/components/Cart/CartBundleSubItem"

import { NormalisedCheckoutLineItem } from "@root/types/custom-types/Checkout/CheckoutLineItem"

type Props = {
  group: NormalisedCheckoutLineItem[]
  remove: string
}

const CartBundleItem: React.FC<Props> = ({ group, remove }) => {
  const { loading, removeItemsFromCart, language } = useCart()
  const { settings } = useConfigContext()
  const { formatMoney } = useShopify()
  const {
    lineItemPropKeyAlcohol,
    lineItemPropKeyLabelImage,
    lineItemPropKeyLabelTitle,
    lineItemPropKeyAddOns,
    lineItemPropKeySticker,
    lineItemPropKeyStickerDate,
    lineItemPropKeyGiftcard,
    lineItemPropKeyGiftcardMessage,
    lineItemPropKeyGiftBox,
  } = settings.personaliser.bundle

  const alcohol = group.find(item => item.customAttributes.find(a => a.key === lineItemPropKeyAlcohol))
  const label = group.find(item => item.customAttributes.find(a => a.key === lineItemPropKeyLabelImage))
  const labelTitle = label?.customAttributes.find(a => a.key === lineItemPropKeyLabelTitle)?.value || language?.cartBundleTitle
  const image = label?.customAttributes.find(a => a.key === lineItemPropKeyLabelImage)?.value
  const addOns = group.filter(item => item.customAttributes.find(a => a.key === lineItemPropKeyAddOns))
  const sticker = group.find(item => item.customAttributes.find(a => a.key === lineItemPropKeySticker))
  const stickerDate = sticker?.customAttributes.find(a => a.key === lineItemPropKeyStickerDate)?.value
  const giftcard = group.find(item => item.customAttributes.find(a => a.key === lineItemPropKeyGiftcard))
  const giftcardMessage = giftcard?.customAttributes.find(a => a.key === lineItemPropKeyGiftcardMessage)?.value
  const giftBox = group.find(item => item.customAttributes.find(a => a.key === lineItemPropKeyGiftBox))

  const totalAmount = group.reduce<number>((acc: number, item: NormalisedCheckoutLineItem) => {
    const amount = parseFloat(item.variant.priceV2.amount) * item.quantity || 0
    return (acc += amount)
  }, 0)
  const compareAtTotalAmount = group.reduce<number>((acc: number, item: NormalisedCheckoutLineItem) => {
    const amount =
      (item.variant.compareAtPriceV2?.amount
        ? parseFloat(item.variant.compareAtPriceV2?.amount)
        : parseFloat(item.variant.priceV2?.amount)) * item.quantity || 0
    return (acc += amount)
  }, 0)
  const total = formatMoney(totalAmount)
  const compareAtTotal = formatMoney(compareAtTotalAmount)
  const onSale = compareAtTotalAmount > totalAmount

  return alcohol && label ? (
    <Box mb={4}>
      <Flex justifyContent="space-between" alignItems="center" gap="3" mb="4">
        <Text size="smSemiSmall">{labelTitle}</Text>
        <Flex alignItems="baseline" gap="1.5">
          {onSale ? (
            <Text as="s" size="smallParagraph">
              {compareAtTotal}
            </Text>
          ) : null}
          <Text color={onSale ? "functional.errorContent" : undefined} size="smallParagraph">
            {total}
          </Text>
        </Flex>
      </Flex>

      <Flex gap={4}>
        <Box w="30" p="2" bg="background.beige" alignSelf="flex-start">
          <AspectRatio ratio={1} sx={{ "> img": { objectFit: "contain" } }}>
            <Image src={image} alt={labelTitle} />
          </AspectRatio>
        </Box>

        <Box w="calc(100% - 120px)">
          <CartBundleSubItem
            title={alcohol.quantity > 1 ? `${alcohol.title} x ${alcohol.quantity}` : alcohol.title}
            image={alcohol.variant.image.transformedSrc}
          />

          {addOns.length
            ? addOns.map(ao =>
                ao.variant.image.transformedSrc ? (
                  <CartBundleSubItem key={ao.id} title={`${ao.title} x ${ao.quantity}`} image={ao.variant.image.transformedSrc} />
                ) : null
              )
            : null}

          {stickerDate && sticker?.variant.image.transformedSrc ? (
            <CartBundleSubItem title={stickerDate} image={sticker?.variant.image.transformedSrc} />
          ) : null}

          {giftcardMessage && giftcard?.variant.image.transformedSrc ? (
            <CartBundleSubItem title={giftcardMessage} image={giftcard?.variant.image.transformedSrc} />
          ) : null}

          {giftBox && giftBox?.variant.image.transformedSrc ? (
            <CartBundleSubItem
              title={giftBox.quantity > 1 ? `${giftBox.title} x ${giftBox.quantity}` : giftBox.title}
              image={giftBox?.variant.image.transformedSrc}
            />
          ) : null}

          <Box textAlign="right">
            <Button
              variant="unstyled"
              disabled={loading}
              onClick={() => removeItemsFromCart(group)}
              fontSize="xs"
              lineHeight="base"
              letterSpacing="wide"
              color="typography.headlines900"
              fontWeight="normal"
              textTransform="capitalize"
              isLoading={loading}
              height="auto"
            >
              {remove}
            </Button>
          </Box>
        </Box>
      </Flex>
    </Box>
  ) : null
}
export default React.memo(CartBundleItem)

export const Text = {
  baseStyle: {
    fontFamily: "body",
    fontWeight: "normal",
    letterSpacing: "wide",
  },
  sizes: {
    largeParagraph: {
      fontSize: "md",
      lineHeight: "1.5",
    },
    mediumParagraph: {
      fontSize: "sm",
      lineHeight: "base",
    },
    smallParagraph: {
      fontSize: "xs",
      lineHeight: "short",
    },
    button: {
      fontSize: "15",
      lineHeight: "1.46",
      letterSpacing: "wide",
    },
    textLinkLarge: {
      fontSize: { base: "md", lg: "2xxl" },
      lineHeight: { base: "shortPlus", lg: "1.23" },
      textDecoration: "underline",
      letterSpacing: { base: "0", lg: "wide" },
    },
    textLinkMedium: {
      fontSize: "md",
      lineHeight: "shortPlus",
      textDecoration: "underline",
      letterSpacing: "0",
    },
    textLinkSmall: {
      fontSize: "sm",
      lineHeight: "base",
      textDecoration: "underline",
      letterSpacing: "0",
    },
    headlines: {
      fontSize: { base: "md", lg: "lg" },
      lineHeight: { base: "1.625", lg: "1.77" },
      fontWeight: "bold",
    },
    smSemiSmall: {
      fontSize: "sm",
      lineHeight: "base",
      fontWeight: "bold",
    },
    smSemiLarge: {
      fontSize: "md",
      lineHeight: "tall",
      fontWeight: "bold",
    },
    smRegular: {
      fontSize: "sm",
      lineHeight: "base",
      letterSpacing: "wide",
    },
    labels: {
      fontSize: "sm",
      lineHeight: "base",
      fontWeight: "bold",
      letterSpacing: "widest",
      textTransform: "uppercase",
    },
    textBase: {
      fontSize: "md",
      lineHeight: "base",
      letterSpacing: "wide",
    },
  },
  variants: {
    underlined: {
      textDecoration: "underline",
    },
  },
}

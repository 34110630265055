import { field } from "./Input"

export const Select = {
  variants: {
    default: {
      field: {
        ...field,
        mb: 0,
      },
      icon: {
        right: 3.5,
      },
    },
  },
  defaultProps: {
    variant: "default",
  },
}

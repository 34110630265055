import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
} from "@chakra-ui/react"

import { useConfigContext } from "@app/providers/config"
import { useAppContext } from "@app/providers/app"
import { useCheckoutContext } from "@app/providers/checkout"
import CartSummary from "@app/components/Cart/CartSummary"
import CartList from "@app/components/Cart/CartList"
import Link from "@app/components/Link"

const CartMini: React.FC = () => {
  const { page, cart } = useStaticQuery<GatsbyTypes.StaticCartMiniQuery>(graphql`
    query StaticCartMini {
      page: sanityPageCart {
        title
      }
      cart: sanityPageCart {
        additionalCheckoutButton
        additionalCartEmpty
        additionalStartShopping
        additionalSubtotal
        additionalDiscounts
        additionalRemove
      }
    }
  `)

  const {
    settings: { routes },
  } = useConfigContext()
  const { count, url, gotoCheckout } = useCheckoutContext()
  const { state, dispatch } = useAppContext()

  const onClose = () => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }

  const cartSummaryLanguage = {
    subtotal: cart?.additionalSubtotal || "",
    discounts: cart?.additionalDiscounts || "",
  }

  return (
    <Drawer isOpen={state.activeCart} placement="right" onClose={onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton top="4" right="4" w="7" h="7" _focus={{ boxShadow: "none" }} _hover={{ bg: "none" }} />
        <DrawerHeader py="4" px="0" mx="4" borderBottom="1px solid" borderColor="border.default">
          <Heading as={Link} to={routes.CART} size="h4">
            {page?.title} {!!count && ` (${count})`}
          </Heading>
        </DrawerHeader>
        <DrawerBody py="8" px="4">
          <CartList empty={cart?.additionalCartEmpty || ""} remove={cart?.additionalRemove || ""} />
        </DrawerBody>
        {!!count && (
          <DrawerFooter d="block" p="4">
            <CartSummary language={cartSummaryLanguage} />

            <Link to={url} w="full">
              <Button variant="solidSecondary" onClick={gotoCheckout} w="full">
                {cart?.additionalCheckoutButton}
              </Button>
            </Link>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}
export default React.memo(CartMini)

import { toggleLabel } from "./Form"

const activeControlStyles = {
  backgroundColor: "brand.primary",
  borderColor: "brand.primary",
}

const disabledControlStyles = {
  backgroundColor: "background.inputDisabled",
  borderColor: "border.default",
  color: "border.default",
}

export const Checkbox = {
  variants: {
    default: {
      container: {},
      control: {
        width: "4.5",
        height: "4.5",
        border: "1px solid",
        borderColor: "border.default",
        borderRadius: "sm",
        svg: {
          width: 3,
          height: 3,
        },
        _checked: {
          ...activeControlStyles,
          _hover: {
            ...activeControlStyles,
          },
          _disabled: {
            ...disabledControlStyles,
          },
        },
        _focus: {
          boxShadow: "none",
        },
        _disabled: {
          ...disabledControlStyles,
        },
      },
      label: {
        ...toggleLabel,
      },
    },
  },
  defaultProps: {
    variant: "default",
  },
}

import React from "react"
import { Script, useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useMeta } from "@app/hooks/useMeta"
import { useConfigContext } from "@app/providers/config"

import type { Location } from "@root/types/global"

type Props = {
  breadcrumbs?: Array<any>
  data: any
  location: Location
}

const Meta: React.FC<Props> = ({ breadcrumbs = [], data, location }) => {
  const { getData, getLanguages, getSchemas, getTags, getTracking } = useMeta()
  const {
    settings: { routes },
  } = useConfigContext()
  const url = location?.pathname
  const languages = getLanguages(url)
  const metadata = getData({
    url,
    data,
    routes,
    breadcrumbs,
    template: data?.template?.metadata,
    language: languages?.find(({ primary }) => primary)?.language?.split("_")?.[0] || "",
  })
  const metatags = getTags(metadata)
  const schemas = getSchemas(metadata)
  const tracking = getTracking()

  const { gtm } = useStaticQuery<GatsbyTypes.StaticGTMQuery>(graphql`
    query StaticGTM {
      gtm: sanitySettingTracking {
        googleTagManagerId
      }
    }
  `)

  return (
    <>
      <Helmet htmlAttributes={{ lang: metadata?.metaLang }} title={metadata?.title} meta={metatags}>
        <link href={metadata?.siteURL} rel="home" />
        <link href={metadata?.canonical} rel="canonical" />
        <link href="//apis.google.com/" rel="dns-prefetch" />
        <link href="//www.googletagmanager.com/" rel="dns-prefetch" />

        {languages?.map(({ key, href, hrefLang, rel }, idx) => (
          <link key={key + "-language-" + idx} href={href} hrefLang={hrefLang} rel={rel} />
        ))}

        {schemas.map((schema, index) => (
          <script type="application/ld+json" key={"schema-script-" + index}>
            {JSON.stringify(schema)}
          </script>
        ))}
      </Helmet>

      {gtm?.googleTagManagerId && (
        <>
          <Script src={`//www.googletagmanager.com/gtm.js?id=${gtm?.googleTagManagerId}`} strategy="idle" />
          <Script src={`//www.googletagmanager.com/gtag/js?id=${gtm?.googleTagManagerId}`} strategy="idle" />
        </>
      )}
      <Script src="//cdn.shopify.com/shopifycloud/shop-js/client.js" strategy="idle" />
      <Script src="//staticw2.yotpo.com/J2ulzcCaqGzA8DznJMXvFDNV5p0wDbwDkrlXbKM6/widget.js" strategy="idle" />
      {/* <Script
        dangerouslySetInnerHTML={{
          __html: `(function e(){var e=document.createElement("script");e.type="text/javascript",e.async=!0, e.src="//staticw2.yotpo.com/J2ulzcCaqGzA8DznJMXvFDNV5p0wDbwDkrlXbKM6/widget.js";var t=document.getElementsByTagName("script")[0]; t.parentNode.insertBefore(e,t)})();`,
        }}
      /> */}
      {tracking.map(script => script)}
    </>
  )
}

export default React.memo(Meta)

module.exports = {
  id: "the-neighbours-cellar",
  title: "The Neighbours Cellar",
  description: "Wine, beer & spirits shop.",
  url: "www.theneighbourscellar.com.au",

  locationRegion: "AU",
  locationCountries: "AU,NZ",
  locationForceRegion: true,
  locationLookupUrl: "https://ipapi.co/json/",

  shopifyPlus: false,
  shopifyApiVersion: "2023-04",
  shopifyShopDomain: "the-neighbours-cellar.myshopify.com",
  shopifyCheckoutUrl: "checkout.theneighbourscellar.com.au",
  shopifyStorefrontToken: "a8f200575306f34236eeaff2d583d926",

  sanityProjectId: "hekfljya",
  sanityDataset: "production",
  sanityApiVersion: "2022-02-24",
  sanityToken:
    "sknbL9C3jrTaibIxx4QauKg0NiHZQkCBSJTzP0MgLWw9ho5Y9LbdasckTgnaJsXNKdXgJv5kf1C2hmQ3yqAOJsSDyqMRzynqrJyul5Smzow1StngzzolCmPNGMJj3S9FEazWtsD47cMaP1lu9KK4Gt16tY4fWRCVg33iU9FJBjjqNFzNtpVE",

  googleAuthClientId: "",
  googleMapsApiKey: "",

  klaviyoApiKey: "V7gHTS",
  emailSender: "george@theneighbourscellar.com.au",
  facebookAppId: "",
}

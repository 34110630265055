import React from "react"
import { Flex, Text } from "@chakra-ui/react"

import { useNavigation } from "@app/hooks/useNavigation"
import Link from "@app/components/Link"
import { MenuItem } from "@app/components/Navigation/Header/NavigationHeader"

const NavigationHeaderSecondaryDesktop: React.FC = () => {
  const { headerSecondary: items } = useNavigation()

  return (
    <Flex gap={{ lg: "5", xl: "7" }}>
      {items.map((item: MenuItem) => (
        <Text key={item.title.toLowerCase()} as={Link} to={item.url} size="smSemiLarge">
          {item.title}
        </Text>
      ))}
    </Flex>
  )
}

export default React.memo(NavigationHeaderSecondaryDesktop)

import React from "react"

import { withLayout } from "@app/hoc/Layout"
import Meta from "@app/components/Meta"
import Main from "@app/components/Main"
import Header from "@app/components/Header"
import CartMini from "@app/components/Cart/CartMini"
import Announcement from "@app/components/Announcement"
import Global from "@app/theme/foundations/global"
import MarketingBanner from "./MarketingBanner"
import Subscribe from "@app/components/Subscribe/Subscribe"
import Footer from "@app/components/Footer"

import type { PageProps } from "@root/types/global"

const Layout: React.FC<PageProps<any>> = ({ children, data, location }) => (
  <>
    <Global />
    <Meta data={data} location={location} />
    <MarketingBanner />
    <Header isHomepage={location?.pathname === "/"} />
    <Announcement />
    <Main>{children}</Main>
    <CartMini />
    <Subscribe />
    <Footer />
  </>
)

export default withLayout(Layout)

import { toggleLabel } from "./Form"

export const Radio = {
  variants: {
    default: {
      control: {
        w: "4.5",
        h: "4.5",
        border: "1px solid",
        borderColor: "border.default",
        _focus: {
          boxShadow: "none",
        },
        _checked: {
          _hover: {
            bg: "brand.primary",
            borderColor: "brand.primary",
          },
          bg: "brand.primary",
          borderColor: "brand.primary",
          _before: {
            w: "8px",
            h: "8px",
          },
          _disabled: {
            bg: "background.inputDisabled",
            color: "border.default",
          },
        },
        _disabled: {
          bg: "background.inputDisabled",
          color: "border.default",
        },
      },
      label: {
        ...toggleLabel,
        _disabled: {
          opacity: 1,
        },
      },
    },
  },
  defaultProps: {
    variant: "default",
  },
}

import React from "react"
import { Box, Container, Heading, Text } from "@chakra-ui/react"
import { useNewsletter } from "@app/hooks/useNewsletter"
import SubscribeForm from "./SubscribeForm"

const Subscribe: React.FC = () => {
  const { newsletter } = useNewsletter()

  return newsletter ? (
    <Box as="section" bg="brand.gradient">
      <Container maxW="container.md" py={{ base: "8", lg: "15" }} px={{ base: "4", lg: "0" }}>
        <Box mb={{ base: "3" }} textAlign="center">
          {newsletter.title && (
            <Heading size="h3" mb={{ base: "1.5" }} color="background.white">
              {newsletter.title}
            </Heading>
          )}
          {newsletter.description && (
            <Text size="headlines" color="background.white">
              {newsletter.description}
            </Text>
          )}
        </Box>

        <SubscribeForm />
      </Container>
    </Box>
  ) : null
}

export default React.memo(Subscribe)

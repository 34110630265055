import React from "react"
import { IconButton } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCheckoutContext } from "@app/providers/checkout"
import { Icon } from "@app/components/Icon"

const CartWidget: React.FC = () => {
  const { count } = useCheckoutContext()
  const { state, dispatch } = useAppContext()

  const handleActiveCart = () => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }

  return (
    <IconButton
      variant="unstyled"
      title={`Open cart (${count})`}
      aria-label={`Open cart (${count})`}
      icon={<Icon name="ui/cart" width="24" height="24" />}
      onClick={handleActiveCart}
      d="inline-flex"
      justifyContent="center"
      alignItems="center"
      w={{ base: "14", lg: "6" }}
      h={{ base: "18", lg: "6" }}
    />
  )
}

export default React.memo(CartWidget)

import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

export const useMeta = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { helpers } = useCore()
  const { global, languages, organisation, social, tracking, scripts } = useStaticQuery<GatsbyTypes.StaticMetaQuery>(graphql`
    query StaticMeta {
      global: sanityTemplateGlobal {
        title
        description
      }
      languages: sanitySettingLanguages {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
      organisation: sanitySettingOrganisation {
        title
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
      tracking: sanitySettingTracking {
        facebookAppId
        googleSiteVerifications
        googleTagManagerId
      }
      scripts: sanitySettingScripts {
        trackingHeader {
          code
        }
        trackingBody {
          code
        }
        trackingFooter {
          code
        }
      }
    }
  `)

  const mapping = {
    page: helpers.schemaWebSite,
    product: helpers.schemaProduct,
    collection: helpers.schemaWebSite,
  }

  const getData = ({ breadcrumbs, data, language, routes, template, url }) =>
    helpers.schemaData({ breadcrumbs, data, global, language, organisation, routes, social, template, tracking, url }, mapping)

  const getTags = (data: any) => helpers.metaTags(data)

  const getLanguages = (route: any) =>
    languages?.hrefLangs?.map(({ _key, language, region, url }, index) => ({
      key: _key,
      primary: !index,
      href: `${url || organisation?.url}${route || ""}`,
      hrefLang: `${region ? `${language}-${region}` : `${language}`}`,
      language: `${region ? `${language}-${region}` : `${language}`}`,
      rel: "alternate",
    }))

  const getSchemas = (data: any) =>
    [helpers.schemaOrg(data), helpers.schemaContent(data), helpers.schemaBreadcrumbs(data)]?.filter(
      schema => schema["@type"] !== "Organization" || data?.pageUrl === `${data.siteURL}${routes.HOMEPAGE}`
    )

  const getTracking = () =>
    helpers?.isDomReady
      ? [
          helpers.renderTrackingScripts(scripts?.trackingHeader?.code || "", { id: "tracking-head", placement: "head" }),
          helpers.renderTrackingScripts(scripts?.trackingBody?.code || "", { id: "tracking-body", placement: "body" }),
          helpers.renderTrackingScripts(scripts?.trackingFooter?.code || "", { id: "tracking-foot", placement: "foot" }),
        ]
      : []

  return {
    getData,
    getLanguages,
    getSchemas,
    getTags,
    getTracking,
  }
}

export const space = {
  0.5: "0.125rem",
  0.75: "0.1875rem",
  4.5: "1.125rem",
  7.5: "1.875rem",
  10.5: "2.625rem",
  13: "3.25rem",
  15: "3.75rem",
  18: "4.5rem",
  19: "4.75rem",
  22.5: "5.625rem",
  24.5: "6.125rem",
  32: "8rem",
  75: "300px",
}

import { graphql, useStaticQuery } from "gatsby"

export const useMarketingBanner = () => {
  const { data } = useStaticQuery<GatsbyTypes.StaticMarketingBannerQuery>(graphql`
    query StaticMarketingBanner {
      data: sanitySettingMarketingBanner {
        link {
          title
          document: _rawDocument(resolveReferences: { maxDepth: 4 })
        }
      }
    }
  `)

  return { link: data?.link }
}

export const label = {
  color: "typography.body600",
  fontSize: "sm",
}

export const toggleLabel = {
  color: "typography.body700",
  fontSize: "sm",
  lineHeight: "base",
  letterSpacing: "wide",
}

const activeLabelStyles = {
  top: "2.5",
  fontSize: "xs",
  lineHeight: "10px",
}

export const Form = {
  variants: {
    default: {},
    floating: {
      container: {
        position: "relative",
        input: {
          pt: "5",
          pb: "1.5",
          mb: "0",
        },
        textarea: {
          py: "8",
          mb: "0",
        },
        select: {
          mb: 0,
        },
        label: {
          ...label,
          position: "absolute",
          top: "3.5",
          left: "4",
          backgroundColor: "transparent",
          pointerEvents: "none",
          transformOrigin: "left top",
          m: "0",
          transition: "all 0.2s ease",
          _invalid: {
            color: "functional.errorContent",
          },
          _disabled: {
            opacity: 1,
          },
        },
        _focusWithin: {
          "input + label": {
            ...activeLabelStyles,
          },
          "textarea ~ label": {
            ...activeLabelStyles,
            top: "3.5",
          },
        },
        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label": {
          ...activeLabelStyles,
        },
        "textarea:not(:placeholder-shown) ~ label": {
          ...activeLabelStyles,
          top: "3.5",
        },
      },
    },
    switchContainer: {
      container: {
        display: "flex",
        alignItems: "center",
        "label + label": {
          ...toggleLabel,
          m: 0,
          ml: 2,
          cursor: "pointer",
          _disabled: {
            opacity: "1",
          },
        },
      },
    },
  },
  defaultProps: {
    variant: "floating",
  },
}

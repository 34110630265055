import React from "react"
import { Flex, Box, AspectRatio, Text, Image } from "@chakra-ui/react"

type Props = {
  title: string
  image: string
}

const CartBundleSubItem: React.FC<Props> = ({ title, image }) => (
  <Flex alignItems="center" gap="2" mb="2">
    <Box w="8" bg="background.beige">
      <AspectRatio ratio={1}>
        <Image src={image} alt={title} />
      </AspectRatio>
    </Box>

    <Text size="smallParagraph">{title}</Text>
  </Flex>
)
export default React.memo(CartBundleSubItem)

import { useCallback, useState } from "react"
import { useQuery, useMutation, useApolloClient } from "@apollo/client"
import { useCore, useStorage } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import { useCustomerLogout } from "@app/hooks/useCustomer"
import { useCheckoutContext } from "@app/providers/checkout"

export const useCheckout = () => {
  const {
    graphql: {
      mutations: {
        CHECKOUT_CREATE,
        CHECKOUT_ATTRIBUTES_UPDATE,
        CHECKOUT_SHIPPING_ADDRESS_UPDATE,
        CHECKOUT_EMAIL_UPDATE,
        CHECKOUT_DISCOUNT_APPLY,
        CHECKOUT_DISCOUNT_REMOVE,
        CHECKOUT_GIFTCARDS_APPEND,
        CHECKOUT_CUSTOMER_ASSOCIATE,
      },
      queries: { GET_SHIPPING_RATES, GET_PRODUCTS_BY_HANDLE },
    },
  } = useCore()
  const { getStorage, setStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  const { checkout, id: checkoutId, countryCode, saveCheckout, createCheckout } = useCheckoutContext()
  const client = useApolloClient()
  const { logoutCustomer } = useCustomerLogout()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<any>>([])

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)
  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE)
  const [checkoutShippingAddressUpdate] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE)
  const [checkoutEmailUpdate] = useMutation(CHECKOUT_EMAIL_UPDATE)
  const [checkoutDiscountApply] = useMutation(CHECKOUT_DISCOUNT_APPLY)
  const [checkoutDiscountRemove] = useMutation(CHECKOUT_DISCOUNT_REMOVE)
  const [checkoutGiftcardAppend] = useMutation(CHECKOUT_GIFTCARDS_APPEND)
  const { refetch: getShippingRatesQuery } = useQuery(GET_SHIPPING_RATES, { fetchPolicy: "no-cache", skip: true })

  const updateAttributes = useCallback(
    async input => {
      const {
        data: { checkoutAttributesUpdateV2: data },
      } = await checkoutAttributeUpdate({
        variables: { checkoutId, countryCode, input },
      })
      saveCheckout(data?.checkout)
    },
    [checkoutAttributeUpdate, countryCode, saveCheckout, checkoutId]
  )

  const updateEmail = useCallback(
    async email => {
      const {
        data: { checkoutEmailUpdateV2: data },
      } = await checkoutEmailUpdate({
        variables: { checkoutId, countryCode, email },
      })
      saveCheckout(data?.checkout)
    },
    [checkoutEmailUpdate, countryCode, saveCheckout, checkoutId]
  )

  const updateCustomer = useCallback(
    async customerAccessToken => {
      const {
        data: { checkoutCustomerAssociateV2: data },
      } = await checkoutCustomerAssociate({
        variables: { checkoutId, customerAccessToken },
      })
      saveCheckout(data?.checkout)
    },
    [checkoutCustomerAssociate, saveCheckout, checkoutId]
  )

  const updateShippingAddress = useCallback(
    async input => {
      const {
        data: { checkoutShippingAddressUpdateV2: data },
      } = await checkoutShippingAddressUpdate({
        variables: {
          checkoutId,
          countryCode,
          shippingAddress: {
            firstName: input?.firstName,
            lastName: input?.lastName,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            country: input?.country,
            phone: input?.phone,
            province: input?.province,
            zip: input?.zip,
          },
        },
      })
      saveCheckout(data?.checkout)
    },
    [checkoutShippingAddressUpdate, countryCode, saveCheckout, checkoutId]
  )

  const applyDiscountCode = useCallback(
    async discountCode => {
      setLoading(true)
      setErrors([])
      const {
        data: { checkoutDiscountCodeApplyV2: data },
      } = await checkoutDiscountApply({
        variables: { checkoutId, countryCode, discountCode },
      })

      if (!data.checkoutUserErrors.length) {
        setStorage(
          keys.discounts,
          [...(getStorage(keys.discounts) || []), discountCode].filter((value, index, self) => self.indexOf(value) === index)
        )
        saveCheckout(data?.checkout)
        setLoading(false)
      } else {
        setLoading(false)
        setErrors(data.checkoutUserErrors)
      }
    },
    [checkoutDiscountApply, countryCode, saveCheckout, setStorage, getStorage, checkoutId, keys]
  )

  const removeDiscountCode = useCallback(async () => {
    setLoading(true)
    const {
      data: { checkoutDiscountCodeRemove: data },
    } = await checkoutDiscountRemove({
      variables: { checkoutId, countryCode },
    })
    setLoading(false)
    if (!data.checkoutUserErrors.length) {
      setStorage(keys?.discounts, [])
      saveCheckout(data?.checkout)
      setLoading(false)
    } else {
      setLoading(false)
      setErrors(data.checkoutUserErrors)
    }
  }, [checkoutDiscountRemove, countryCode, saveCheckout, setStorage, checkoutId, keys?.discounts])

  const applyDiscounts = useCallback(async () => {
    const discountCodes = getStorage(keys.discounts) || []
    for (const discountCode of discountCodes) {
      await applyDiscountCode(discountCode)
    }
  }, [applyDiscountCode, getStorage, keys.discounts])

  const applyGiftCardCode = useCallback(
    async giftCardCode => {
      setLoading(true)
      setErrors([])
      const {
        data: { checkoutGiftCardsAppend: data },
      } = await checkoutGiftcardAppend({
        variables: { checkoutId, countryCode, giftCardCodes: [giftCardCode] },
      })

      if (!data.checkoutUserErrors.length) {
        saveCheckout(data?.checkout)
        setLoading(false)
      } else {
        setLoading(false)
        setErrors(data.checkoutUserErrors)
      }
    },
    [checkoutGiftcardAppend, countryCode, saveCheckout, checkoutId]
  )

  const getShippingRates = useCallback(async () => {
    const {
      data: { node: checkout },
    } = await getShippingRatesQuery({ countryCode, id: checkoutId })
    saveCheckout(checkout)
    return checkout
  }, [getShippingRatesQuery, saveCheckout, countryCode, checkoutId])

  const duplicateCheckout = useCallback(
    async (countryCode: string) => {
      const { customAttributes, lineItems, note, email, shippingAddress } = checkout
      const discounts = getStorage(keys.discounts) || []

      const {
        data: { checkoutCreate: data },
      } = await checkoutCreate({
        variables: {
          input: {
            buyerIdentity: {
              countryCode,
            },
            ...(email && { email }),
            ...(note && { note }),
            ...(customAttributes && {
              customAttributes:
                customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
                  key,
                  value,
                })) || [],
            }),
            lineItems:
              lineItems?.map((item: any) => ({
                variantId: item?.variant.id,
                quantity: item?.quantity || 1,
                customAttributes:
                  item?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
                    key,
                    value,
                  })) || [],
              })) || [],
            shippingAddress: shippingAddress
              ? {
                  address1: shippingAddress.address1,
                  address2: shippingAddress.address2,
                  city: shippingAddress.city,
                  company: shippingAddress.company,
                  country: shippingAddress.country,
                  firstName: shippingAddress.firstName,
                  lastName: shippingAddress.lastName,
                  phone: shippingAddress.phone,
                  province: shippingAddress.province,
                  zip: shippingAddress.zip,
                }
              : undefined,
          },
        },
      })
      if (!discounts.length) saveCheckout(data?.checkout)
      if (discounts.length) applyDiscounts()
    },
    [checkout, checkoutCreate, saveCheckout, applyDiscounts, getStorage, keys]
  )

  const migrateCheckout = useCallback(
    async (changes = {}) => {
      const { lineItems, note, email, customAttributes, shippingAddress } = checkout
      const discounts = getStorage(keys.discounts) || []

      if (lineItems?.length) {
        const mappedLineItems =
          lineItems?.map((item: any) => ({
            handle: item?.variant?.product?.handle,
            sku: item?.variant?.sku,
            quantity: item?.quantity,
            customAttributes: item?.customAttributes,
          })) || []

        const { data: matchedProducts } = await client.query({
          query: GET_PRODUCTS_BY_HANDLE(mappedLineItems.map((product: any) => product?.handle)),
          variables: {
            countryCode,
            firstCollections: 0,
            firstImages: 0,
            firstMedia: 0,
            firstMetafields: 0,
            firstVariants: 100,
            metafieldIdentifiers: [],
          },
        })

        const migratedLineItems =
          mappedLineItems.map((lineItem: any) => ({
            variantId: matchedProducts[`product${lineItem?.handle?.replace(/-/g, "")}`]?.variants?.edges
              ?.filter(({ node }: { node: any }) => node?.sku === lineItem?.sku)
              .map(({ node }: { node: any }) => node?.id)[0],
            quantity: lineItem?.quantity,
            customAttributes: lineItem?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
              key,
              value,
            })),
          })) || []

        const {
          data: { checkoutCreate: data },
        } = await checkoutCreate({
          variables: {
            input: {
              buyerIdentity: {
                countryCode,
              },
              ...(email && { email }),
              ...(note && { note }),
              ...(customAttributes && {
                customAttributes:
                  customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
                    key,
                    value,
                  })) || [],
              }),
              lineItems: migratedLineItems,
              shippingAddress: shippingAddress || undefined,
              ...changes,
            },
          },
        })
        if (!discounts.length) saveCheckout(data?.checkout)
        if (discounts.length) applyDiscounts()
        logoutCustomer()
      } else {
        createCheckout(countryCode, true)
      }
    },
    [
      keys,
      client,
      checkout,
      countryCode,
      getStorage,
      saveCheckout,
      createCheckout,
      checkoutCreate,
      applyDiscounts,
      logoutCustomer,
      GET_PRODUCTS_BY_HANDLE,
    ]
  )

  const updateCurrency = useCallback(
    async countryCode => {
      setLoading(true)
      await duplicateCheckout(countryCode)
      setLoading(false)
    },
    [duplicateCheckout]
  )

  return {
    errors,
    loading,
    updateEmail,
    updateCustomer,
    updateCurrency,
    migrateCheckout,
    updateAttributes,
    getShippingRates,
    applyGiftCardCode,
    applyDiscountCode,
    removeDiscountCode,
    updateShippingAddress,
  }
}

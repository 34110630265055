import * as React from "react"
import { IconButton } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { Icon } from "@app/components/Icon"

const NavigationHeaderWidget: React.FC = () => {
  const { state, dispatch } = useAppContext()

  const onToggle = React.useCallback(() => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }, [dispatch, state.activeMenu])

  return (
    <IconButton
      variant="unstyled"
      colorScheme="brand.text"
      aria-label="Open Menu"
      icon={<Icon name={state.activeMenu ? "ui/close" : "ui/hamburger"} width="24" height="24" />}
      onClick={onToggle}
      d="inline-flex"
      justifyContent="center"
      alignItems="center"
      w="56px"
      h="72px"
    />
  )
}

export default React.memo(NavigationHeaderWidget)

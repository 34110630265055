import { useCore } from "@app/hooks/useCore"
import React, { useState, useReducer, useRef, useCallback, useEffect } from "react"

export type ProductMenuItem = {
  title: string
  ref: React.RefObject<HTMLDivElement>
}

type ContextProps = {
  state: {
    activeMenu: boolean
    activeCart: boolean
    activeSearch: boolean
    activeSubscribe: boolean
  }
  dispatch: React.Dispatch<any>
  activeOptions: any | null
  setActiveOptions: (state: any) => void
  activeVariant: any | null
  setActiveVariant: (state: any) => void
  activeProduct: any | null
  setActiveProduct: (state: any) => void
  activeCollection: any | null
  setActiveCollection: (state: any) => void
  productMenuItems: ProductMenuItem[]
  setProductMenuItems: React.Dispatch<React.SetStateAction<ProductMenuItem[]>>
  headerRef: React.RefObject<HTMLDivElement> | null
  saveProductItems: (menuTitle: string | undefined, sectionRef: React.RefObject<HTMLDivElement>) => void
  productStickyAtcRef: React.RefObject<HTMLDivElement> | null
}

export const AppContext = React.createContext<ContextProps | undefined>(undefined)

export const AppProvider: React.FC = ({ children }) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const [activeOptions, setActiveOptions] = useState(null)
  const [activeVariant, setActiveVariant] = useState(null)
  const [activeProduct, setActiveProduct] = useState(null)
  const [activeCollection, setActiveCollection] = useState(null)
  const [productMenuItems, setProductMenuItems] = useState<ProductMenuItem[]>([])

  const headerRef = useRef<HTMLDivElement>(null)
  const productStickyAtcRef = useRef<HTMLDivElement>(null)

  const initialValues = {
    activeMenu: false,
    activeCart: false,
    activeSearch: false,
    activeSubscribe: false,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "initial":
        return { ...state, ...initialValues }
      case "menu":
        return { ...state, activeMenu: action.payload, activeSearch: false, activeCart: false, activeSubscribe: false }
      case "cart":
        return { ...state, activeCart: action.payload, activeSearch: false, activeMenu: false, activeSubscribe: false }
      case "search":
        return { ...state, activeSearch: action.payload, activeCart: false, activeMenu: false, activeSubscribe: false }
      case "subscribe":
        return { ...state, activeSubscribe: action.payload, activeSearch: false, activeCart: false, activeMenu: false }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues)

  const saveProductItems = useCallback(
    (menuTitle: string | undefined, sectionRef: React.RefObject<HTMLDivElement>) => {
      if (!menuTitle) return
      setProductMenuItems(prevItems => {
        const value = {
          title: menuTitle,
          ref: sectionRef,
        }

        const newItems = prevItems.map(item => {
          if (item.title === menuTitle) return value
          return item
        })
        if (!newItems.find(i => i.title === menuTitle)) newItems.push(value)

        return newItems
      })
    },
    [setProductMenuItems]
  )

  useEffect(() => {
    if (!isBrowser) return

    const setHeight = (): void => {
      document.documentElement.style.setProperty(`--window-height`, `${window.innerHeight}px`)
    }

    setHeight()

    window.addEventListener("resize", setHeight)
    return () => {
      if (isBrowser) window.removeEventListener("resize", setHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      state,
      dispatch,
      activeOptions,
      setActiveOptions,
      activeVariant,
      setActiveVariant,
      activeProduct,
      setActiveProduct,
      activeCollection,
      setActiveCollection,
      productMenuItems,
      setProductMenuItems,
      headerRef,
      saveProductItems,
      productStickyAtcRef,
    }),
    [
      state,
      dispatch,
      activeOptions,
      setActiveOptions,
      activeVariant,
      setActiveVariant,
      activeProduct,
      setActiveProduct,
      activeCollection,
      setActiveCollection,
      productMenuItems,
      setProductMenuItems,
      headerRef,
      saveProductItems,
      productStickyAtcRef,
    ]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): ContextProps => ({ ...React.useContext(AppContext) }) as ContextProps

const baseStyles = {
  fontSize: "sm",
  border: "2px solid",
  height: "auto",
  letterSpacing: "widest",
}

const primaryHoverStyles = {
  backgroundColor: "brand.primaryFocus",
  borderColor: "brand.primaryFocus",
}

const outlineHoverStyles = {
  ...primaryHoverStyles,
  borderColor: "brand.primaryFocus",
  color: "background.white",
}

const secondaryHoverStyles = {
  backgroundColor: "brand.secondary",
  borderColor: "brand.secondary",
}

const outlineSecondaryHoverStyles = {
  backgroundColor: "brand.secondaryFocus",
  borderColor: "brand.secondaryFocus",
  color: "background.white",
}

const outlineInvertedHoverStyles = {
  backgroundColor: "background.white",
  borderColor: "background.white",
  color: "typography.body700",
}

const solidDisabledStyles = {
  backgroundColor: "border.light",
  borderColor: "border.light",
  color: "typography.body500",
}

const outlineDisabledStyles = {
  backgroundColor: "background.white",
  borderColor: "border.default",
  color: "typography.body500",
}

export const Button = {
  baseStyle: {
    px: 6,
    borderRadius: "xl",
    fontSize: "sm",
    lineHeight: "base",
    letterSpacing: "wide",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: "body",
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      opacity: "1",
      pointerEvents: "none",
    },
  },
  sizes: {
    md: {
      py: "11px",
      minW: "0",
    },
    lg: {
      py: "15px",
      minW: "0",
    },
  },
  variants: {
    solid: {
      ...baseStyles,
      backgroundColor: "brand.primary",
      borderColor: "brand.primary",
      color: "background.white",
      _hover: {
        ...primaryHoverStyles,
      },
      _active: {
        ...primaryHoverStyles,
      },
      _disabled: {
        ...solidDisabledStyles,
      },
    },
    outline: {
      ...baseStyles,
      backgroundColor: "background.white",
      borderColor: "brand.primary",
      color: "brand.primary",
      _hover: {
        ...outlineHoverStyles,
      },
      _active: {
        ...outlineHoverStyles,
      },
      _disabled: {
        ...outlineDisabledStyles,
      },
    },
    solidSecondary: {
      ...baseStyles,
      backgroundColor: "brand.secondaryFocus",
      borderColor: "brand.secondaryFocus",
      color: "background.white",
      _hover: {
        ...secondaryHoverStyles,
      },
      _active: {
        ...secondaryHoverStyles,
      },
      _disabled: {
        ...solidDisabledStyles,
      },
    },
    outlineSecondary: {
      ...baseStyles,
      backgroundColor: "background.white",
      borderColor: "brand.secondary",
      color: "typography.headlines900",
      _hover: {
        ...outlineSecondaryHoverStyles,
      },
      _active: {
        ...outlineSecondaryHoverStyles,
      },
      _disabled: {
        ...outlineDisabledStyles,
      },
    },
    outlineInverted: {
      ...baseStyles,
      color: "background.white",
      _hover: {
        ...outlineInvertedHoverStyles,
      },
      _active: {
        ...outlineInvertedHoverStyles,
      },
      _disabled: {
        ...solidDisabledStyles,
      },
    },
    blackInverted: {
      ...baseStyles,
      backgroundColor: "background.white",
      borderColor: "background.white",
      color: "background.black",
      _hover: {
        ...outlineInvertedHoverStyles,
      },
      _active: {
        ...outlineInvertedHoverStyles,
      },
      _disabled: {
        ...solidDisabledStyles,
      },
    },
    quantity: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      w: 12,
      h: "100%",
      p: 0,
      border: "none",
      cursor: "pointer",
      _disabled: {
        backgroundColor: "transparent",
        span: {
          opacity: 0.5,
        },
      },
    },
    unstyled: {
      textTransform: "initial",
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
}

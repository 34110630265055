import React, { useEffect } from "react"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useAppContext } from "@app/providers/app"
import { useShopifyProduct } from "@app/hooks/useShopify"
import { useMaintenance } from "@app/hooks/useMaintenance"

import type { Location } from "@root/types/global"

export const withLayout =
  (Component: React.FC<{ data: any; location: Location }>) =>
  ({ name = "Layout", location, children, data }: any) => {
    const { active, authorised } = useMaintenance(location)
    const { selectProduct } = useShopifyProduct()
    const { tracked, trackPageView } = useAnalytics()
    const { dispatch } = useAppContext()

    useEffect(() => {
      dispatch({ type: "initial" })
    }, [location?.pathname, dispatch])

    useEffect(() => {
      trackPageView()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, tracked])

    useEffect(() => {
      selectProduct(data?.product, location?.pathname)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, data?.product])

    Component.displayName = name
    return active ? (
      <>{children}</>
    ) : (
      authorised && (
        <Component data={data} location={location}>
          {children}
        </Component>
      )
    )
  }

export const FormError = {
  baseStyle: {
    text: {
      mt: 1.5,
      fontFamily: "body",
      fontWeight: "normal",
      letterSpacing: "wide",
      fontSize: "sm",
      lineHeight: "base",
      color: "functional.errorContent",
    },
  },
}

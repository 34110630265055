import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Flex, VisuallyHidden } from "@chakra-ui/react"
import Link from "@app/components/Link"
import { Icon } from "@app/components/Icon"
import { useCore } from "@app/hooks/useCore"

const SocialMedia: React.FC = () => {
  const { social } = useStaticQuery<GatsbyTypes.StaticSocialMediaQuery>(graphql`
    query StaticSocialMedia {
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
    }
  `)
  const {
    helpers: { ErrorBoundary },
  } = useCore()

  return (
    <ErrorBoundary>
      <Flex
        gap="8"
        justifyContent={{ base: "center", lg: "flex-start" }}
        pt={{ base: "5", md: "7.5", lg: "0" }}
        pb={{ base: "6", md: "0" }}
      >
        {social?.channels?.map(({ title, url }) => {
          const iconName = `social/${title.toLowerCase()}`
          return (
            <Link key={title} to={url || ""} external w="4.5" h="4.5">
              <Icon name={iconName} width="100%" height="100%" />
              <VisuallyHidden>{title}</VisuallyHidden>
            </Link>
          )
        })}
      </Flex>
    </ErrorBoundary>
  )
}

export default React.memo(SocialMedia)

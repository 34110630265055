import React from "react"
import { Box } from "@chakra-ui/react"

import Link from "@app/components/Link"
import Logo from "@root/static/icons/brand/logo.svg"

type Props = {
  tag?: string
}

const Brand: React.FC<Props> = ({ tag }) => (
  <Box as={tag || "div"}>
    <Link d="block" to="/">
      <Logo />
    </Link>
  </Box>
)

export default React.memo(Brand)

import { useState, useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMutation } from "@apollo/client"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useCheckoutContext } from "@app/providers/checkout"
import { NormalisedCheckoutLineItem } from "@root/types/custom-types/Checkout/CheckoutLineItem"
import { BundleTrackingData } from "./useBundle"

type LineItemAttribute = { [key: string]: string }
export type AddToCartItem = {
  variantId: string
  quantity?: number
  customAttributes?: LineItemAttribute[]
}

export const useCart = () => {
  const {
    graphql: {
      mutations: { CHECKOUT_LINE_ITEMS_REPLACE, CHECKOUT_ATTRIBUTES_UPDATE },
    },
  } = useCore()

  const { checkout, id: checkoutId, countryCode, saveCheckout } = useCheckoutContext()
  const { checkoutNormaliser, formatMoney } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)

  const updateAttributes = async input => {
    const {
      data: { checkoutAttributesUpdateV2: data },
    } = await checkoutAttributeUpdate({
      variables: { checkoutId, countryCode, input },
    })
    saveCheckout(data?.checkout)
  }

  const prepareCustomAttributes = useCallback(
    (attributes: LineItemAttribute[]) =>
      attributes?.map(({ key, value }) => ({
        key,
        value,
      })) || [],
    []
  )

  const addToCart = useCallback(
    async (item: AddToCartItem) => {
      const { variantId, quantity = 1, customAttributes = [] } = item

      setLoading(true)
      let alreadyInCart = false

      const lineItems =
        checkout?.lineItems?.map((lineItem: any) => {
          if (
            lineItem?.variant?.id === variantId &&
            JSON.stringify(prepareCustomAttributes(lineItem?.customAttributes)) === JSON.stringify(customAttributes)
          ) {
            alreadyInCart = true
            return {
              customAttributes: [...prepareCustomAttributes(lineItem?.customAttributes), ...(customAttributes || [])],
              quantity: lineItem?.quantity + quantity,
              variantId,
            }
          }
          return {
            customAttributes: lineItem?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            variantId: lineItem?.variant?.id,
          }
        }) || []

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: [...(alreadyInCart ? lineItems : [...lineItems, { quantity, variantId, customAttributes }])],
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)

      setLoading(false)
      trackCartUpdate("add", variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [
      lineItemsReplace,
      setErrors,
      saveCheckout,
      setLoading,
      trackCartUpdate,
      countryCode,
      checkout,
      checkoutId,
      checkoutNormaliser,
      prepareCustomAttributes,
    ]
  )

  const addToCartMultiple = useCallback(
    async (items: AddToCartItem[], bundle?: BundleTrackingData) => {
      setLoading(true)
      const findVariant = (id: string, customAttributes: any) =>
        items?.find(
          (lineItem: any) =>
            lineItem?.variantId === id &&
            JSON.stringify(prepareCustomAttributes(lineItem?.customAttributes)) === JSON.stringify(customAttributes)
        )
      const foundVariants: Array<string> = []

      const lineItems =
        checkout?.lineItems?.map((lineItem: any) => {
          const existingVariant = findVariant(lineItem?.variantId, lineItem?.customAttributes)
          if (existingVariant) {
            foundVariants.push(lineItem?.variant?.id)
            return {
              customAttributes: [...prepareCustomAttributes(lineItem?.customAttributes), ...(existingVariant?.customAttributes || [])],
              quantity: lineItem?.quantity + existingVariant?.quantity,
              variantId: existingVariant?.variantId,
            }
          }
          return {
            customAttributes: lineItem?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            variantId: lineItem?.variant?.id,
          }
        }) || []

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: [...lineItems, ...items.filter(({ variantId }: any) => !foundVariants.includes(variantId))],
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)

      trackCartUpdate("add", checkoutNormaliser(data?.checkout)?.lineItems, items, bundle)
    },
    [
      lineItemsReplace,
      setErrors,
      saveCheckout,
      setLoading,
      trackCartUpdate,
      countryCode,
      checkout,
      checkoutId,
      checkoutNormaliser,
      prepareCustomAttributes,
    ]
  )

  const removeFromCart = useCallback(
    async (id, variantId) => {
      setLoading(true)
      const quantity = checkout?.lineItems.filter(lineItem => lineItem.id === id).map(({ quantity }) => quantity)[0] || 1

      trackCartUpdate("remove", variantId, quantity, checkout?.lineItems)

      const lineItems = checkout?.lineItems
        .filter(lineItem => lineItem.id !== id)
        .map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, trackCartUpdate, countryCode, checkout, checkoutId]
  )

  const removeItemsFromCart = useCallback(
    async (items: NormalisedCheckoutLineItem[]) => {
      setLoading(true)

      items.forEach(({ variant, quantity }) => trackCartUpdate("remove", variant.id, quantity, checkout?.lineItems))

      const toRemoveIds = items.map(item => item.id)
      const lineItems = checkout?.lineItems
        .filter(item => !toRemoveIds.find(id => id === item.id))
        .map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)
    },
    [checkout, trackCartUpdate, lineItemsReplace, saveCheckout, setErrors, checkoutId, countryCode]
  )

  const updateQuantity = useCallback(
    async (id, variantId, quantity, action = "add") => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map((lineItem: any) => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
        }),
        quantity: lineItem.id === id ? quantity : lineItem.quantity,
        variantId: lineItem.variant.id,
      }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)

      trackCartUpdate(action, variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, trackCartUpdate, countryCode, checkout, checkoutId, checkoutNormaliser]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map((lineItem: any) => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
        }),
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
      }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, countryCode, checkout, checkoutId]
  )

  const updateItem = useCallback(
    async (id, variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map((lineItem: any) =>
        lineItem.id === id
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...prepareCustomAttributes(lineItem?.customAttributes),
                    ...Object.entries(customAttributes).map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, countryCode, checkout, checkoutId, prepareCustomAttributes]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)

    checkout?.lineItems?.map(({ variant, quantity }: { variant: any; quantity: number }) =>
      trackCartUpdate("remove", variant?.id, quantity, checkout?.lineItems)
    )

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: { countryCode, checkoutId, lineItems: [] },
    })

    if (errors?.length) setErrors(errors)
    if (data) saveCheckout(data?.checkout)

    setLoading(false)
  }, [lineItemsReplace, setErrors, saveCheckout, setLoading, trackCartUpdate, countryCode, checkout, checkoutId])

  const { shipping, language } = useStaticQuery<GatsbyTypes.StaticCartQuery>(graphql`
    query StaticCart {
      shipping: sanitySettingShipping {
        threshold
        includeDiscounts
        messageBefore
        messageAfter
      }
      language: sanityTemplateGlobal {
        cartBundleTitle
      }
    }
  `)

  const freeShipping = useMemo(() => {
    const threshold = shipping?.threshold ?? 100
    const includeDiscounts = shipping?.includeDiscounts ?? true
    const total = includeDiscounts ? checkout?.lineItemsSubtotalPrice?.amount : checkout?.paymentDueV2?.amount
    const percentage = total ? (total > threshold ? 100 : (total / threshold) * 100) : 0
    const remaining = total ? (total > threshold ? 0 : threshold - total) : 0
    const rawMessage = percentage === 100 ? shipping?.messageAfter : shipping?.messageBefore
    const message =
      percentage === 0 && remaining === 0
        ? undefined
        : rawMessage?.replace("{threshold}", formatMoney(threshold))?.replace("{remaining}", formatMoney(remaining))
    return { threshold, percentage, message }
  }, [shipping, checkout?.paymentDueV2?.amount, checkout?.lineItemsSubtotalPrice?.amount, formatMoney])

  return {
    addToCart,
    addToCartMultiple,
    removeFromCart,
    removeItemsFromCart,
    updateQuantity,
    updateVariant,
    updateItem,
    clearCart,
    freeShipping,
    loading,
    errors,
    language,
    updateAttributes,
  }
}

import { useStaticQuery, graphql } from "gatsby"

export const useAnnouncement = () => {
  const { data } = useStaticQuery<GatsbyTypes.StaticAnnouncementsQuery>(graphql`
    query StaticAnnouncements {
      data: sanitySettingAnnouncement {
        announcements {
          title
          link {
            link
            external
          }
        }
      }
    }
  `)

  const items = data?.announcements || []

  return {
    items,
  }
}

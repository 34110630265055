import React, { useCallback, useEffect, useState } from "react"
import { Flex, Box, Show, Container, Text, Fade } from "@chakra-ui/react"

import { useCore } from "@app/hooks/useCore"
import { useAppContext } from "@app/providers/app"
import Brand from "@app/components/Brand"
import CartWidget from "@app/components/Cart/CartWidget"
import NavigationHeader from "@app/components/Navigation/Header/NavigationHeader"
import NavigationHeaderWidget from "@app/components/Navigation/Header/NavigationHeaderWidget"
import NavigationHeaderSecondaryDesktop from "@app/components/Navigation/Header/NavigationHeaderSecondaryDesktop"

type Props = {
  isHomepage: boolean
}

const Header: React.FC<Props> = ({ isHomepage }) => {
  const { productMenuItems, headerRef, productStickyAtcRef } = useAppContext()
  const {
    helpers: { isBrowser },
  } = useCore()

  const [showProductNav, setShowProductNav] = useState(false)

  const onProductItemClick = (ref: React.RefObject<HTMLDivElement>) => {
    if (!isBrowser) return

    const headerOffset = headerRef?.current?.querySelector(".chakra-fade > div")?.getBoundingClientRect().height || 0
    const headerHeight = headerRef?.current?.getBoundingClientRect()?.height || 0
    const refTop = ref?.current?.getBoundingClientRect()?.top || 0
    const yDestination = refTop + window.scrollY - (headerHeight + headerOffset)

    window.scrollTo({ top: yDestination, left: 0, behavior: "smooth" })
  }

  const onScroll = useCallback(() => {
    if (!isBrowser) return

    const sectionRef = productMenuItems.length && productMenuItems[1] ? productMenuItems[1].ref : null
    if (sectionRef?.current) {
      const offset = productStickyAtcRef?.current?.getBoundingClientRect().height || 0
      const top = sectionRef.current.getBoundingClientRect().top - window.innerHeight + offset

      if (top > 0) {
        setShowProductNav(false)
      } else {
        setShowProductNav(true)
      }
    }
  }, [productMenuItems, productStickyAtcRef, isBrowser])

  useEffect(() => {
    if (!isBrowser) return

    document.addEventListener("scroll", onScroll)
    return () => document.removeEventListener("scroll", onScroll)
  }, [onScroll, isBrowser])

  return (
    <Container as="header" ref={headerRef} pos="sticky" top="0" maxW="container.4xl" px="0" bg="background.white" zIndex="sticky">
      <Flex pos="relative" justifyContent="space-between" py={{ lg: "5" }} px={{ lg: "6", xl: "20" }}>
        <Show below="lg">
          <NavigationHeaderWidget />
        </Show>
        <NavigationHeader />

        <Box pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" w={{ base: "124px", lg: "150px" }}>
          <Brand tag={isHomepage ? "h1" : null} />
        </Box>

        <Flex gap={{ lg: 4, xl: 6 }} alignItems="center">
          <Show above="lg">
            <NavigationHeaderSecondaryDesktop />
          </Show>
          <CartWidget />
        </Flex>
      </Flex>

      {productMenuItems.length ? (
        <Fade in={showProductNav} unmountOnExit transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}>
          <Box bg="brand.gradient" overflow="hidden" pos="absolute" top="100%" left="0" w="full">
            <Container maxW="container.4xl" px={{ base: "0", lg: "6", xl: "20" }}>
              <Flex
                overflow="auto"
                scrollSnapType="x mandatory"
                scrollPadding={{ base: "4", lg: "0" }}
                sx={{
                  scrollbarWidth: { base: "none" },
                  "::-webkit-scrollbar": {
                    display: { base: "none" },
                  },
                }}
              >
                {productMenuItems.map(item => (
                  <Text
                    key={item.title.toLowerCase()}
                    as="button"
                    onClick={() => onProductItemClick(item.ref)}
                    size="smSemiLarge"
                    flexShrink={0}
                    py={{ base: "3", lg: "4" }}
                    mr="5"
                    color="background.white"
                    scrollSnapAlign="start"
                    _first={{ ml: { base: "5", lg: "0" } }}
                    _last={{ mr: { base: "5", lg: "0" } }}
                  >
                    {item.title}
                  </Text>
                ))}
              </Flex>
            </Container>
          </Box>
        </Fade>
      ) : null}
    </Container>
  )
}

export default Header

import React, { useMemo } from "react"
import { useMarketingBanner } from "@app/hooks/useMarketingBanner"
import { useRoutes } from "@app/hooks/useRoutes"
import { Box, Center, Container, Text } from "@chakra-ui/react"
import Link from "@app/components/Link"

const MarketingBanner: React.FC = () => {
  const { link: data } = useMarketingBanner()
  const { urlResolver } = useRoutes()

  const link = useMemo(() => urlResolver(data?.document), [data?.document, urlResolver])
  const textProps = { size: "smSemiSmall", color: "background.white" }

  return data?.title ? (
    <Box as="section" bg="brand.secondaryFocus">
      <Container maxW="8xl">
        <Center py={{ base: "2", lg: "2.5" }}>
          {link.url ? (
            <Text as={Link} to={link.url} external={link.external} {...textProps} title={link.title} textAlign="center">
              {data.title}
            </Text>
          ) : (
            <Text {...textProps}>{data.title}</Text>
          )}
        </Center>
      </Container>
    </Box>
  ) : null
}

export default React.memo(MarketingBanner)

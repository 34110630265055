import { createBreakpoints } from "@chakra-ui/theme-tools"

export const container = {
  xs: "23.4375em",
  sm: "40em",
  md: "46.875em",
  lg: "64em",
  xl: "80em",
  "2xl": "90em",
  "4xl": "125rem",
}

export const breakpoints = createBreakpoints(container)

import React, { lazy } from "react"
import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"

const NavigationFooter: React.FC = () => {
  const {
    helpers: { ErrorBoundary, isBrowser },
  } = useCore()
  const { isMedium } = useMedia()

  const NavigationFooterDesktop = lazy(() => import("./NavigationFooterDesktop"))
  const NavigationFooterMobile = lazy(() => import("./NavigationFooterMobile"))

  return isBrowser ? <ErrorBoundary>{isMedium ? <NavigationFooterDesktop /> : <NavigationFooterMobile />}</ErrorBoundary> : null
}

export default React.memo(NavigationFooter)

export const colors = {
  brand: {
    primary: "#B58D81",
    primaryFocus: "#A07466",
    secondary: "#DECFFC",
    secondaryFocus: "#CDB6FB",
    accent: "#E9D7B9",
    accentFocus: "#E3CEAB",
    accent2: "#E5BF9E",
    gradient: "linear-gradient(90.71deg, #F4DCB3 0%, #FCB6CB 66.72%, #D0BCF9 100%)",
  },
  functional: {
    info: "#4E9CF3",
    success: "#EDFFD8",
    successContent: "#9ECF64",
    warning: "#FFEDD5",
    warningContent: "#C2630C",
    error: "#FFEFF0",
    errorContent: "#C25C62",
  },
  background: {
    white: "#FFFFFF",
    beige: "#FAF6F0",
    overlay: "rgba(0, 0, 0, 0.2)",
    overlay50: "rgba(0, 0, 0, 0.5)",
    overlay75: "rgba(0, 0, 0, 0.75)",
    inputDisabled: "#F8F8F8",
    teal: "#9BC8D1",
    pink: "#E3A8AB",
    gradientStop: "#44D3CB",
    gradientBanner: "linear-gradient(91.33deg, #A0E06D 0%, #44D3CB 99.16%)",
    starInactive: "#AD84FF",
    starActive: "#e7721b",
    grey100: "#FAF6F0",
    tabsGrey: "#F6F6F6",
  },
  typography: {
    headlines900: "#161514",
    body700: "#404040",
    body600: "#737373",
    body500: "#929292",
    body100: "#FAF6F0",
    white: "#FFFFFF",
    link: "#404040",
    linkFocus: "#A07466",
  },
  border: {
    default: "#E4E4E4",
    light: "#F4F4F4",
    focus: "#555555",
    error: "#C25C62",
    accentTop: "#E9DDCD",
  },
}

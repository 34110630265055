import React from "react"
import { HStack, Text, Skeleton } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import { useShopifyPrice } from "@app/hooks/useShopify"

type Props = {
  variant?: any
  loading?: boolean
}

const ProductPrice: React.FC<Props> = ({ variant, loading = false }) => {
  const { activeVariant } = useAppContext()
  const { formattedPrice, formattedCompareAtPrice, onSale } = useShopifyPrice(variant || activeVariant)

  return (
    <Skeleton width={16} isLoaded={!loading && formattedPrice !== undefined} mb="2">
      <HStack>
        <Text as={onSale ? "s" : undefined} size="smallParagraph">
          {onSale ? formattedCompareAtPrice : formattedPrice}
        </Text>
        {onSale ? (
          <Text color="functional.errorContent" size="smallParagraph">
            &nbsp;{formattedPrice}
          </Text>
        ) : null}
      </HStack>
    </Skeleton>
  )
}

export default React.memo(ProductPrice)
